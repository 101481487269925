<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <div class="flex items-center">
        <img src="@/assets/images/icons8-team-96.png" class="h-20 ml-2" />
        <div class="text-xl font-bold">کاربران کانال</div>
      </div>
      <h2 class="text-xl font-bold">
        <Icon name="Group"></Icon>
        {{ channel.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ channel.gid }}</div>
    </template>
    <template #default>
      <div
        v-if="!channel.pro_tools"
        class="
          p-3
          bg-red-100
          border-red-200
          text-red-900
          font-bold
          border
          rounded-md
          mb-5
          block
        "
      >
        <div class="flex items-center">
          <img src="@/assets/images/icons8-brawl-stars-96.png" />
          این قابلیت فقط برای اشتراک‌های آبی، برنزی، نقره‌ای و طلایی وجود دارد.
        </div>
      </div>
      <!-- search box -->

      <div class="p-3 bg-blueGray-200 border border-blueGray-300 rounded-md">
        <div class="text-lg font-bold">
          <img
            src="@/assets/images/icons8-team-search-96.png"
            class="inline-block h-20"
          />
          جستجوی کاربران
        </div>
        <form @submit.prevent="submit">
          <div class="grid sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
            <MnrText
              v-model="searchTools.search"
              title="جستجو"
              placeholder="جستجو کنید"
            ></MnrText>
          </div>
          <div
            class="grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-3 mt-3"
            v-if="searchTools.proSearch"
          >
            <MnrSelect
              v-model="searchTools.vip"
              title="کاربران VIP"
              placeholder="همه"
              :options="options.vip"
            ></MnrSelect>
            <MnrSelect
              v-model="searchTools.status"
              title="وضعیت کاربر"
              placeholder="همه"
              :options="options.status"
              :dValue="0"
              dText="همه"
            ></MnrSelect>
            <MnrSelect
              v-model="searchTools.order"
              title="ترتیب بر اساس"
              placeholder="آخرین تغییرات"
              :options="options.order"
            ></MnrSelect>
            <MnrSelect
              v-model="searchTools.sort"
              title="چیدمان"
              placeholder="بیشترین - نزولی"
              :options="options.sort"
            ></MnrSelect>
          </div>
          <div v-if="searchTools.addSearch">
            <hr class="my-4 border-blueGray-300" />
            <div class="grid sm:grid-cols-2 grid-cols-1 gap-5 mt-3">
              <div>
                <MnrCheckSlider v-model:checked="searchTools.minAdd.active">
                  جستجو بر اساس کمترین دعوت
                </MnrCheckSlider>
                <MnrNumber
                  v-model="searchTools.minAdd.value"
                  :disabled="!searchTools.minAdd.active"
                />
              </div>
              <div>
                <MnrCheckSlider v-model:checked="searchTools.maxAdd.active">
                  جستجو بر اساس بیشترین دعوت
                </MnrCheckSlider>
                <MnrNumber
                  v-model="searchTools.maxAdd.value"
                  :disabled="!searchTools.maxAdd.active"
                />
              </div>
            </div>
            <hr class="my-4 border-blueGray-300" />
            <div class="grid sm:grid-cols-2 grid-cols-1 gap-5 mt-3">
              <div>
                <MnrCheckSlider v-model:checked="searchTools.start.active">
                  مشخص کردن تاریخ شروع دعوت
                </MnrCheckSlider>
                <div class="flex gap-1">
                  <MnrNumber
                    v-model="date.start.day"
                    class="w-1/4"
                    placeholder="روز"
                    hint="روز"
                    :counter="false"
                    :disabled="!searchTools.start.active"
                  />
                  <MnrSelect
                    v-model="date.start.month"
                    :options="options.month"
                    class="w-2/4"
                    placeholder="ماه"
                    hint="ماه"
                    :disabled="!searchTools.start.active"
                  />
                  <MnrNumber
                    v-model="date.start.year"
                    class="w-1/4"
                    placeholder="سال"
                    hint="سال"
                    :counter="false"
                    :disabled="!searchTools.start.active"
                  />
                </div>
              </div>
              <div>
                <MnrCheckSlider v-model:checked="searchTools.end.active">
                  مشخص کردن تاریخ پایان دعوت
                </MnrCheckSlider>
                <div class="flex gap-1">
                  <MnrNumber
                    v-model="date.end.day"
                    class="w-1/4"
                    placeholder="روز"
                    hint="روز"
                    :counter="false"
                    :disabled="!searchTools.end.active"
                  />
                  <MnrSelect
                    v-model="date.end.month"
                    :options="options.month"
                    class="w-2/4"
                    placeholder="ماه"
                    hint="ماه"
                    :disabled="!searchTools.end.active"
                  />
                  <MnrNumber
                    v-model="date.end.year"
                    class="w-1/4"
                    placeholder="سال"
                    hint="سال"
                    :counter="false"
                    :disabled="!searchTools.end.active"
                  />
                </div>
              </div>
            </div>
          </div>
          <hr class="my-4 border-blueGray-300" />
          <MnrCheckSlider v-model:checked="searchTools.proSearch">
            نمایش گزینه های بیشتر
          </MnrCheckSlider>
          <MnrCheckSlider v-model:checked="searchTools.addSearch">
            جستجو بر اساس دعوت
          </MnrCheckSlider>
          <div class="sm:w-60 w-full mt-3 mx-auto">
            <Button
              type="submit"
              class="mt-3 text-sm h-11 lg:text-lg"
              color="sky"
              :loading="sending"
            >
              جستجو
            </Button>
          </div>
        </form>
      </div>

      <div class="text-xl font-bold my-5">
        نمایش {{ users?.length || 0 }} کاربر از {{ count || 0 }} کاربر
      </div>

      <!-- users -->
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 mt-5">
        <div
          v-for="user in users"
          :key="user.id"
          class="bg-blueGray-100 border border-blueGray-200 rounded-md overflow-hidden"
        >
          <div class="flex p-2 bg-blueGray-200">
            <div class="w-1/2 font-bold text-lg">{{ user.first_name }}</div>
            <div class="w-1/2 font-bold text-lg text-left">{{ user.last_name }}</div>
          </div>
          <div class="flex p-2">
            <div class="w-1/2">
              <span v-if="user.userChannel.vip">⭐️</span>
              id
            </div>
            <div class="w-1/2 font-bold text-left" dir="ltr">{{ user.uid }}</div>
          </div>
          <div class="flex p-2">
            <div class="w-1/2">نام کاربری</div>
            <div class="w-1/2 font-bold text-left" dir="ltr">
              {{ user.username ? "@" + user.username : "-" }}
            </div>
          </div>
          <div class="flex p-2">
            <div class="w-1/2">وضعیت</div>
            <div class="w-1/2 font-bold text-left">{{ user.userChannel.status }}</div>
          </div>
          <div class="flex p-2" v-if="user.counted_add">
            <div class="w-1/2">تعداد دعوت</div>
            <div class="w-1/2 font-bold text-left">
              {{ user.counted_add }}
            </div>
          </div>
          <div class="flex p-2" v-if="channel.vip && searchTools.vip == 'vip'">
            <div class="w-1/2">پایان اشتراک</div>
            <div class="w-1/2 font-bold text-left">
              {{ user.userChannel.vip_exp?.fa }}
            </div>
          </div>
          <div class="w-full mx-auto p-2">
            <Button
              :to="{
                name: 'ChannelOwnerSettingShowUser',
                params: {
                  id: $store.state.user.id,
                  channelId: $route.params.channelId,
                  userId: user.id,
                },
              }"
              class="text-sm h-11 lg:text-lg"
              color="cyan"
            >
              بیشتر
            </Button>
          </div>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";

import MnrText from "@/components/mnr/MnrText.vue";

import Button from "@/components/Button.vue";
import MnrSelect from "@/components/mnr/MnrSelect.vue";
import _ from "lodash";
import MnrNumber from "@/components/mnr/MnrNumber.vue";
import MnrCheckSlider from "@/components/mnr/MnrCheckSlider.vue";

export default {
  components: {
    BodySimple,
    Icon,
    Button,
    MnrText,
    MnrSelect,
    MnrNumber,
    MnrCheckSlider,
  },
  data() {
    return {
      errors: [],
      firstLoading: true,
      channel: {},
      users: [],
      count: 0,
      L_: _,
      sending: false,
      date: {},
      options: {
        sort: [
          {
            text: "بیشترین - نزولی",
            value: "desc",
          },
          {
            text: "کمترین - صعودی",
            value: "asc",
          },
        ],
        order: [
          {
            text: "آخرین تغییرات",
            value: "update",
          },
          {
            text: "ثبت",
            value: "create",
          },
          {
            text: "تعداد دعوت",
            value: "add",
          },
          {
            text: "تاریخ VIP",
            value: "vip",
          },
        ],
        vip: [
          {
            text: "همه",
            value: "all",
          },
          {
            text: "فقط کاربران VIP",
            value: "vip",
          },
          {
            text: "فقط کاربران معمولی",
            value: "normal",
          },
        ],
        status: [
          {
            text: "restricted",
            hint: "محدود شده",
            value: "restricted",
          },
          {
            text: "kicked",
            hint: "اخراج شده",
            value: "kicked",
          },
          {
            text: "left",
            hint: "خارج شده",
            value: "left",
          },
          {
            text: "member",
            hint: "کاربر",
            value: "member",
          },
          {
            text: "administrator",
            hint: "ادمین",
            value: "administrator",
          },
          {
            text: "creator",
            hint: "سازنده",
            value: "creator",
          },
        ],
        month: [
          {
            text: "فروردین",
            hint: 1,
            value: 1,
          },
          {
            text: "اردیبهشت",
            hint: 2,
            value: 2,
          },
          {
            text: "خرداد",
            hint: 3,
            value: 3,
          },
          {
            text: "تیر",
            hint: 4,
            value: 4,
          },
          {
            text: "مرداد",
            hint: 5,
            value: 5,
          },
          {
            text: "شهریور",
            hint: 6,
            value: 6,
          },
          {
            text: "مهر",
            hint: 7,
            value: 7,
          },
          {
            text: "آبان",
            hint: 8,
            value: 8,
          },
          {
            text: "آذر",
            hint: 9,
            value: 9,
          },
          {
            text: "دی",
            hint: 10,
            value: 10,
          },
          {
            text: "بهمن",
            hint: 11,
            value: 11,
          },
          {
            text: "اسفند",
            hint: 12,
            value: 12,
          },
        ],
      },
      searchTools: {
        search: "",
        proSearch: false,
        addSearch: false,
        vip: "all",
        status: 0,
        branch: 0,
        order: "update",
        sort: "desc",
        minAdd: {
          active: false,
          value: 0,
        },
        maxAdd: {
          active: false,
          value: 0,
        },
        start: {
          active: false,
          day: 0,
          month: 0,
          year: 0,
        },
        end: {
          active: false,
          day: 0,
          month: 0,
          year: 0,
        },
      },
    };
  },
  mounted() {
    let $this = this;

    this.$router
      .isReady()
      .then(function () {
        $this.searchTools.search = $this.$route.query.search ?? $this.searchTools.search;
        $this.searchTools.proSearch =
          Number($this.$route.query.proSearch) ?? $this.searchTools.proSearch;
        $this.searchTools.addSearch =
          Number($this.$route.query.addSearch) ?? $this.searchTools.addSearch;
        $this.searchTools.status = $this.$route.query.status ?? $this.searchTools.status;
        $this.searchTools.branch =
          Number($this.$route.query.branch) ?? $this.searchTools.branch;
        $this.searchTools.order = $this.$route.query.order ?? $this.searchTools.order;
        $this.searchTools.sort = $this.$route.query.sort ?? $this.searchTools.sort;

        $this.getData();
      })
      .catch();
  },
  methods: {
    setQuery() {
      this.$router.replace({
        query: {
          search: this.searchTools.search,
          proSearch: this.searchTools.proSearch ? 1 : 0,
          addSearch: this.searchTools.addSearch ? 1 : 0,
          status: this.searchTools.status,
          branch: this.searchTools.branch,
          order: this.searchTools.order,
          sort: this.searchTools.sort,
          vip: this.searchTools.vip,
        },
      });
    },
    getData() {
      let $this = this;
      // $this.firstLoading = true;
      $this.$axios
        .get("/api/owner-channels/" + $this.$route.params.channelId + "/users", {
          params: {
            id: $this.$store.state.user.id,
            search: $this.searchTools,
            date: $this.date,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.channel = response.data.channel;
          $this.users = response.data.users;
          $this.count = response.data.count;
          $this.date = response.data.date;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.firstLoading = false;
          $this.sending = false;
        });
    },
    submit() {
      this.setQuery();
      // console.log("submit");
      // return;
      let $this = this;
      $this.sending = true;
      // console.log(id, update);
      $this.errors = [];
      this.getData();
    },
  },
};
</script>

<style></style>
